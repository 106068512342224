const   toggle = $('dt'),
        toggleContent = $('dd')


$(function () {

    toggle.stop().click(function () {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).next().removeClass('active');
      } else {
        $(this).parent().children().removeClass('active');
        $(this).addClass('active');
        if ($(this).next().is('dd')) {
          $(this).parent().children('dd').removeClass('active');
          $(this).next().addClass('active')
        }
      }
    });
  
  });



  $( document ).ready(function() {

    $('.all').click(function(){
      $('.secondary-menu li a').removeClass('active');
      $(this).addClass('active');
      $('.laminierenbutton').slideDown();
      $('.lackierenbutton').slideDown();
      $('.kombibutton').slideDown();
      return false
    })
    $('.laminieren').click(function(){
      $('.secondary-menu li a').removeClass('active');
      $(this).addClass('active');
      $('.laminierenbutton').slideDown();
      $('.lackierenbutton').slideUp(100);
      $('.kombibutton').slideUp(100);
      return false
    })
    $('.lackieren').click(function(){
      $('.secondary-menu li a').removeClass('active');
      $(this).addClass('active');
      $('.lackierenbutton').slideDown();
      $('.laminierenbutton').slideUp(100);
      $('.kombibutton').slideUp(100);
      return false
    })
    $('.kombi').click(function(){
      $('.secondary-menu li a').removeClass('active');
      $(this).addClass('active');
      $('.kombibutton').slideDown();
      $('.lackierenbutton').slideUp(100);
      $('.laminierenbutton').slideUp(100);
      return false
    })
});


 