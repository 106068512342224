$(window).scroll(function () {
  let wScroll = $(this).scrollTop();
  let headerImage = $('.main');

  headerImage.css('top', wScroll / 2 + 'px')



  
  if ($(window).innerWidth() > 1200) {
    
    if (wScroll > 30) {
      $('.header-main').addClass('mobile');
      $('.header-main').removeClass('desktop');
    } else {
      $('.header-main').removeClass('mobile');
      $('.header-main').addClass('desktop');
    }}
    

    if($('body').hasClass('people')){

      var profile = $('.people #homepage-profile').offset().top,
        profileOffset = profile - wScroll;  
  
  
  
    if (profileOffset <= 600) {
      $('#homepage-profile .profile').addClass('slideIn');
      $('#homepage-profile .profileSecond').addClass('slideIn');
      $('#homepage-profile .profileThird').addClass('slideIn');
 
    } 
    
    else {
      $('#homepage-profile .profileThird').removeClass('slideIn');
      $('#homepage-profile .profileSecond').removeClass('slideIn');
      $('#homepage-profile .profile').removeClass('slideIn');

    }
   



    }



});


$(window).bind("resize", function () {

  if ($(window).innerWidth() > 1200) {


    $(window).scroll(function () {
      let wScroll = $(this).scrollTop();
      if (wScroll > 30) {
        $('.header-main').addClass('mobile');
        $('.header-main').removeClass('desktop');
      } else {
        $('.header-main').removeClass('mobile');
        $('.header-main').addClass('desktop');
      }
    });
    
  } 
});